// import Vue from 'vue';
// import PrimeVue from 'primevue/config';

// import "primevue/resources/themes/saga-blue/theme.css"       //theme
// import "primevue/resources/primevue.min.css"                 //core css

// Vue.use(PrimeVue);

import Vue from 'vue';
import PrimeVue from 'primevue/config';
import "primevue/resources/themes/saga-blue/theme.css";  // PrimeVue theme (choose any theme you like)
import "primevue/resources/primevue.min.css";            // Core PrimeVue CSS
import "primeicons/primeicons.css";                      // PrimeIcons CSS (for icons)

// Use the PrimeVue plugin
Vue.use(PrimeVue);
