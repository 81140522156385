<template>
    <div class="filter-list">
        <div class="text-start">
            <slot name="top-end"></slot>
        </div>
        <div v-if="!hideTop" class="row align-items-center">
            <!-- <div v-if="!hideTotal" class="col-12 mt-3" :class="classTotal">
        <slot name="total">
          <h4>
            {{ $t("nomber") }} {{ pluralName }} :
            <span class="m-c"> {{ metaInfo.total }} {{ singleName }} </span>
          </h4>
        </slot> 
      </div>-->
            <div v-if="!hideTitle" class="col-12 mt-3" :class="classTitle">
                <slot name="title">
                    <h3 v-if="title" class="t-c fw-bolder">{{ title }}</h3>
                </slot>
            </div>

            <!-- adding a new button to the filter if it only open providers page -->
            <div
                v-if="providersPage || readyServicesPage || proposalPage"
                class="row provider"
            >
                <div class="col-lg-3 mt-3">
                    <div class="w-75 position-relative">
                        <!-- Column for select input -->
                        <slot name="order">
                            <div class="input-group">
                                <select
                                    @change="updateFilter"
                                    class="form-select py-3 m-c fs-r-14"
                                    aria-label=".form-select-lg example"
                                >
                                    <option
                                        v-for="(location, i) in locations"
                                        :key="i"
                                    >
                                        {{ location.name }}
                                    </option>
                                </select>
                                <p
                                    style="
                                        top: -10px;
                                        right: 0px;
                                        color: #c7c7c7;
                                        background: #fff;
                                    "
                                    class="position-absolute fs-r-12"
                                >
                                    الموقع
                                </p>
                            </div>
                        </slot>
                    </div>
                </div>
                <div class="mt-3 mb-3 justify-content-md-center col-lg-9">
                    <div
                        v-if="showBtnSearch"
                        class="text-center"
                        :class="classBtnSearch"
                    >
                        <button @click="updateFilter" class="btn-custmer">
                            {{ $t("search") }}
                        </button>
                    </div>
                    <div v-if="!hideSearch" class="row mb-3 w-100">
                        <slot name="search">
                            <label
                                for=""
                                class="position-relative col-8 col-md-10"
                            >
                                <input
                                    class="form-control py-3 px-5 fs-r-12"
                                    type="text"
                                    v-model="filter.search"
                                    @change="updateFilter"
                                    :placeholder="searchPlaceholder"
                                />
                                <p
                                    style="top: 15%; right: 20px"
                                    class="position-absolute"
                                >
                                    <svg
                                        width="34"
                                        height="34"
                                        viewBox="0 0 34 34"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            width="34"
                                            height="34"
                                            rx="8"
                                            fill="#1FB9B3"
                                        />
                                        <path
                                            d="M26.7114 25.2894L23.0014 21.6094C24.4415 19.8138 25.1389 17.5347 24.9502 15.2407C24.7615 12.9467 23.701 10.8122 21.9869 9.27606C20.2727 7.73992 18.0352 6.91893 15.7343 6.98189C13.4334 7.04485 11.2441 7.98698 9.61651 9.61456C7.98893 11.2421 7.0468 13.4315 6.98384 15.7323C6.92088 18.0332 7.74188 20.2708 9.27801 21.9849C10.8142 23.6991 12.9487 24.7595 15.2427 24.9482C17.5367 25.1369 19.8158 24.4395 21.6114 22.9994L25.2914 26.6794C25.3843 26.7731 25.4949 26.8475 25.6168 26.8983C25.7387 26.9491 25.8694 26.9752 26.0014 26.9752C26.1334 26.9752 26.2641 26.9491 26.3859 26.8983C26.5078 26.8475 26.6184 26.7731 26.7114 26.6794C26.8916 26.493 26.9924 26.2438 26.9924 25.9844C26.9924 25.7251 26.8916 25.4759 26.7114 25.2894ZM16.0014 22.9994C14.6169 22.9994 13.2635 22.5889 12.1124 21.8197C10.9612 21.0505 10.064 19.9573 9.53421 18.6782C9.0044 17.3991 8.86578 15.9917 9.13587 14.6338C9.40597 13.2759 10.0727 12.0286 11.0516 11.0497C12.0306 10.0707 13.2779 9.40402 14.6357 9.13392C15.9936 8.86382 17.4011 9.00245 18.6802 9.53226C19.9592 10.0621 21.0525 10.9593 21.8217 12.1104C22.5908 13.2616 23.0014 14.6149 23.0014 15.9994C23.0014 17.8559 22.2639 19.6364 20.9511 20.9492C19.6384 22.2619 17.8579 22.9994 16.0014 22.9994Z"
                                            fill="white"
                                        />
                                    </svg>
                                </p>
                            </label>
                        </slot>
                        <button
                            v-if="proposalPage"
                            @click="openAddService($event)"
                            class="col-4 col-md-2 req-btn"
                        >
                            {{ $t("add-new-request") }}
                        </button>
                        <button
                            v-else
                            @click="openAddService($event)"
                            class="col-4 col-md-2 req-btn"
                        >
                            {{
                                userIsRoleProvider
                                    ? $t("add-new-service")
                                    : $t("add-new-request")
                            }}
                        </button>
                    </div>

                    <div v-if="!hideOrder" class="row align-items-center">
                        <div class="col position-relative">
                            <!-- Column for select input -->
                            <slot name="order">
                                <div class="input-group">
                                    <select
                                        @change="updateFilter"
                                        v-model="filter.order"
                                        class="form-select py-3 m-c fs-r-16 w-100 text-left"
                                        aria-label=".form-select-lg example"
                                    >
                                        <option
                                            v-for="(opt, i) in orderOpts"
                                            :key="i"
                                            :value="opt.id"
                                        >
                                            {{ opt.name }}
                                        </option>
                                    </select>
                                    <p
                                        style="
                                            top: 15px;
                                            left: 100px;
                                            color: #c7c7c7;
                                        "
                                        class="position-absolute fs-r-14"
                                    >
                                        ترتيب حسب:
                                    </p>
                                    <div
                                        style="top: 11px; right: 15px"
                                        class="position-absolute style-buttons"
                                    >
                                        <div class="form-switch d-flex gap-1">
                                            <!-- Grid Button -->
                                            <button
                                                @click="changeView('grid')"
                                                :class="
                                                    'btn btn-sm' +
                                                    (showGrid ? ' active' : '')
                                                "
                                            >
                                                <svg
                                                    width="1.25em"
                                                    height="1em"
                                                    viewBox="0 0 16 16"
                                                    class="bi bi-grid-3x3-gap"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M1 1h5v5H1V1zm0 6h5v5H1V7zm6-6h5v5H7V1zm0 6h5v5H7V7zm6-6h5v5h-5V1zm0 6h5v5h-5V7zm0 6h5v5h-5v-5zm-6 0h5v5H7v-5zm6 0h5v5h-5v-5z"
                                                    />
                                                </svg>
                                            </button>
                                            <!-- List Button -->
                                            <button
                                                @click="changeView('list')"
                                                :class="
                                                    'btn btn-sm' +
                                                    (showList ? ' active' : '')
                                                "
                                            >
                                                <svg
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 16 16"
                                                    class="bi bi-list task"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M1 2.5A.5.5 0 0 1 1.5 2h13a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1zm0 3A.5.5 0 0 1 1.5 5h13a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1zm0 3A.5.5 0 0 1 1.5 8h13a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1z"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>

            <!-- to any page in the site. -->
            <div v-else class="" :class="classSearchOrder">
                <div
                    v-if="showBtnSearch"
                    class="text-center"
                    :class="classBtnSearch"
                >
                    <button @click="updateFilter" class="btn-custmer">
                        {{ $t("search") }}
                    </button>
                </div>
                <div v-if="!hideSearch" :class="classColSearch">
                    <slot name="search">
                        <label for="" class="position-relative w-100">
                            <input
                                class="form-control py-3 px-5 fs-r-12"
                                type="text"
                                v-model="filter.search"
                                @change="updateFilter"
                                :placeholder="searchPlaceholder"
                            />
                            <p
                                style="top: 20%; right: 7px"
                                class="position-absolute"
                            >
                                <svg
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        width="34"
                                        height="34"
                                        rx="8"
                                        fill="#1FB9B3"
                                    />
                                    <path
                                        d="M26.7114 25.2894L23.0014 21.6094C24.4415 19.8138 25.1389 17.5347 24.9502 15.2407C24.7615 12.9467 23.701 10.8122 21.9869 9.27606C20.2727 7.73992 18.0352 6.91893 15.7343 6.98189C13.4334 7.04485 11.2441 7.98698 9.61651 9.61456C7.98893 11.2421 7.0468 13.4315 6.98384 15.7323C6.92088 18.0332 7.74188 20.2708 9.27801 21.9849C10.8142 23.6991 12.9487 24.7595 15.2427 24.9482C17.5367 25.1369 19.8158 24.4395 21.6114 22.9994L25.2914 26.6794C25.3843 26.7731 25.4949 26.8475 25.6168 26.8983C25.7387 26.9491 25.8694 26.9752 26.0014 26.9752C26.1334 26.9752 26.2641 26.9491 26.3859 26.8983C26.5078 26.8475 26.6184 26.7731 26.7114 26.6794C26.8916 26.493 26.9924 26.2438 26.9924 25.9844C26.9924 25.7251 26.8916 25.4759 26.7114 25.2894ZM16.0014 22.9994C14.6169 22.9994 13.2635 22.5889 12.1124 21.8197C10.9612 21.0505 10.064 19.9573 9.53421 18.6782C9.0044 17.3991 8.86578 15.9917 9.13587 14.6338C9.40597 13.2759 10.0727 12.0286 11.0516 11.0497C12.0306 10.0707 13.2779 9.40402 14.6357 9.13392C15.9936 8.86382 17.4011 9.00245 18.6802 9.53226C19.9592 10.0621 21.0525 10.9593 21.8217 12.1104C22.5908 13.2616 23.0014 14.6149 23.0014 15.9994C23.0014 17.8559 22.2639 19.6364 20.9511 20.9492C19.6384 22.2619 17.8579 22.9994 16.0014 22.9994Z"
                                        fill="white"
                                    />
                                </svg>
                            </p>
                        </label>
                    </slot>
                </div>
                <div v-if="!hideOrder" :class="classColOrder">
                    <slot name="order">
                        <select
                            @change="updateFilter"
                            v-model="filter.order"
                            class="form-select py-3 m-c fs-r-12"
                            aria-label=".form-select-lg example"
                        >
                            <option
                                v-for="(opt, i) in orderOpts"
                                :key="i"
                                :value="opt.id"
                            >
                                {{ opt.name }}
                            </option>
                        </select>
                        <p
                            style="top: -13px; right: 24px; background: white"
                            class="position-absolute"
                        >
                            ترتيب حسب
                        </p>
                    </slot>
                    <!-- View Grid or list -->
                </div>
                <div v-if="showViewOpt" class="col-2 text-left">
                    <div class="form-switch d-flex py-3 text-left gap-1">
                        <!-- Grid Button -->
                        <button
                            @click="changeView('grid')"
                            :class="
                                'btn btn-sm btn-outline-secondary' +
                                (showGrid ? ' active' : '')
                            "
                        >
                            <svg
                                width="1.25em"
                                height="1em"
                                viewBox="0 0 16 16"
                                class="bi bi-grid-3x3-gap"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M1 1h5v5H1V1zm0 6h5v5H1V7zm6-6h5v5H7V1zm0 6h5v5H7V7zm6-6h5v5h-5V1zm0 6h5v5h-5V7zm0 6h5v5h-5v-5zm-6 0h5v5H7v-5zm6 0h5v5h-5v-5z"
                                />
                            </svg>
                        </button>
                        <!-- List Button -->
                        <button
                            @click="changeView('list')"
                            :class="
                                'btn btn-sm btn-outline-secondary' +
                                (showList ? ' active' : '')
                            "
                        >
                            <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                class="bi bi-list task"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M1 2.5A.5.5 0 0 1 1.5 2h13a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1zm0 3A.5.5 0 0 1 1.5 5h13a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1zm0 3A.5.5 0 0 1 1.5 8h13a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1z"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <!--ordzer-->
            <div
                v-if="$slots['head-end']"
                :class="HeadEndClasses"
                :style="{
                    'margin-right': $i18n.locale ? 'auto' : '',
                    'margin-left': $i18n.locale ? 'auto' : '',
                }"
            >
                <slot name="head-end"></slot>
            </div>
        </div>
        <div v-if="$slots['before-body']" class="row filter-list__before-body">
            <slot name="before-body"></slot>
        </div>
        <div class="row">
            <div v-if="!hideSide" class="mt-2" :class="classSideBar">
                <slot name="side">
                    <div class="box border p-3 rounded-3">
                        <h4>{{ $t("filter") }}</h4>
                        <div
                            class="accordion"
                            id="accordionPanelsStayOpenExample"
                        >
                            <div class="accordion-item">
                                <h2
                                    class="accordion-header"
                                    id="panelsStayOpen-headingOne"
                                >
                                    <button
                                        class="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseOne"
                                        aria-expanded="true"
                                        aria-controls="panelsStayOpen-collapseOne"
                                    >
                                        تصنيف
                                    </button>
                                </h2>
                                <div
                                    id="panelsStayOpen-collapseOne"
                                    class="accordion-collapse collapse show"
                                    aria-labelledby="panelsStayOpen-headingOne"
                                >
                                    <div class="accordion-body">
                                        <div>
                                            <div
                                                v-for="(state, i) in states"
                                                :key="i"
                                                class="form-check"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    :value="state.id"
                                                    v-model="stateValue"
                                                    :selected="
                                                        state.id === state
                                                    "
                                                    name="stateRadioDefault"
                                                    id="flexRadioDefault1"
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="flexRadioDefault1"
                                                >
                                                    {{ state.name }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2
                                    class="accordion-header"
                                    id="panelsStayOpen-headingTwo"
                                >
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="panelsStayOpen-collapseTwo"
                                    >
                                        مجالات الاختصاص
                                    </button>
                                </h2>
                                <div
                                    id="panelsStayOpen-collapseTwo"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="panelsStayOpen-headingTwo"
                                >
                                    <div class="accordion-body">
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="defaultCheck1"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="defaultCheck1"
                                            >
                                                الازياء
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="defaultCheck2"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="defaultCheck2"
                                            >
                                                المجوهرات
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="defaultCheck3"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="defaultCheck3"
                                            >
                                                تجميل
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="defaultCheck4"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="defaultCheck4"
                                            >
                                                منظمات الحفلات
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="defaultCheck5"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="defaultCheck5"
                                            >
                                                التصوير الفوتوغرافية
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="defaultCheck6"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="defaultCheck6"
                                            >
                                                التصميم
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="defaultCheck7"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="defaultCheck7"
                                            >
                                                مصممات الديكور
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="defaultCheck8"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="defaultCheck8"
                                            >
                                                العاملين في الافراح
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseThree"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree"
                  >
                    المدة
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseThree"
                  class="accordion-collapse"
                  aria-labelledby="panelsStayOpen-headingThree"
                >
                  <div style="margin: 20px 0px 0 0" class="a">
                    <div class="slider-container">
                      <rslider-input
                        :min.sync="valueMin2"
                        :max.sync="valueMax2"
                        :lmin="0"
                        :lmax="100"
                      />
                    </div>
                  </div>
                </div>
              </div> -->
                            <!-- <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFour"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFour"
                  >
                    التكلفة
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseFour"
                  class="accordion-collapse"
                  aria-labelledby="panelsStayOpen-headingFour"
                >
                  <div style="margin: 20px 0px 0 0" class="a">
                    <div class="slider-container">
                      <rslider-input
                        :min.sync="valueMin1"
                        :max.sync="valueMax1"
                        :lmin="50"
                        :lmax="1000"
                      />
                    </div>
                  </div>
                </div>
              </div> -->
                        </div>
                        <div class="mt-3 text-center">
                            <button class="btn-main">حفظ</button>
                        </div>
                    </div>
                </slot>
            </div>
            <div
                :class="{
                    'col-xl-9': !hideSide,
                    'col-lg-12': hideSide,
                    'col-xl-12': newFilter,
                }"
            >
                <div class="row">
                    <div v-if="items.length == 0">
                        <h1 class="t-c text-center">لا يوجد عناصر</h1>
                    </div>
                    <div v-else class="col-12 row order filter-list-items">
                        <div
                            v-for="(item, i) in items"
                            :key="i"
                            :class="showGrid ? classColCard : 'col-12'"
                        >
                            <slot :item="item"></slot>
                        </div>
                    </div>
                    <div :class="showGrid ? classColCard : 'col-12'">
                        <slot name="list" :items="items"></slot>
                    </div>
                </div>
            </div>
            <div class="mt-5">
                <nav
                    v-if="metaInfo.total_page > 0"
                    aria-label="Page navigation example"
                >
                    <ul
                        class="pagination justify-content-center gap-3"
                        :style="{
                            'flex-direction':
                                $i18n.locale == 'ar' ? 'row-reverse' : 'row',
                        }"
                    >
                        <li
                            class="page-item rotateY-180"
                            :class="{ disabled: metaInfo.current_page == 1 }"
                        >
                            <button
                                @click="changePage(1)"
                                class="page-link prev-btn"
                            >
                                <svg
                                    width="10"
                                    height="15"
                                    viewBox="0 0 10 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.90013 14.3899C1.58346 14.3899 1.2668 14.2733 1.0168 14.0233C0.533464 13.5399 0.533464 12.7399 1.0168 12.2566L6.0168 7.25661L1.0168 2.25661C0.533464 1.77327 0.533464 0.973275 1.0168 0.489941C1.50013 0.00660808 2.30013 0.00660808 2.78346 0.489941L8.6668 6.37328C9.15013 6.85661 9.15013 7.65661 8.6668 8.13994L2.78346 14.0233C2.53346 14.2733 2.2168 14.3899 1.90013 14.3899Z"
                                        :fill="
                                            metaInfo.current_page == 1
                                                ? '#CDD7D8'
                                                : '#1FB9B3'
                                        "
                                    />
                                </svg>
                            </button>
                        </li>
                        <li
                            v-for="(p, i) in metaInfo.total_page"
                            :key="i"
                            class="page-item"
                            :class="{ disabled: metaInfo.current_page === p }"
                        >
                            <button
                                @click="changePage(p)"
                                class="page-link page-number border-0"
                                href="#"
                            >
                                {{ p }}
                            </button>
                        </li>

                        <li
                            class="page-item rotateY-180"
                            :class="{
                                disabled:
                                    metaInfo.current_page ==
                                    metaInfo.total_page,
                            }"
                        >
                            <button
                                @click="changePage(metaInfo.total_page)"
                                class="page-link next-btn"
                                href="#"
                            >
                                <svg
                                    width="10"
                                    height="15"
                                    viewBox="0 0 10 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.09987 14.3899C7.7832 14.3899 7.46653 14.2733 7.21653 14.0233L1.3332 8.13994C0.84987 7.65661 0.84987 6.85661 1.3332 6.37328L7.21653 0.489941C7.69987 0.00660808 8.49987 0.00660808 8.9832 0.489941C9.46654 0.973275 9.46654 1.77327 8.9832 2.25661L3.9832 7.25661L8.9832 12.2566C9.46654 12.7399 9.46654 13.5399 8.9832 14.0233C8.74987 14.2733 8.4332 14.3899 8.09987 14.3899Z"
                                        :fill="
                                            metaInfo.current_page ==
                                            metaInfo.total_page
                                                ? '#CDD7D8'
                                                : '#1FB9B3'
                                        "
                                    />
                                </svg>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "d-filter-list",
    props: {
        title: {
            type: String,
            default: null,
        },
        pluralName: {
            type: String,
            default: function () {
                return this.$t("services");
            },
        },
        searchPlaceholder: {
            type: String,
            default: function () {
                return this.$t("search");
            },
        },

        singleName: {
            type: String,
            default: function () {
                return this.$t("service");
            },
        },
        classColCard: {
            type: String,
            default: "col-12 col-lg-4 ",
        },
        classColSearch: {
            type: String,
            default: "col-12 col-lg-4",
        },
        classTotal: {
            type: String,
            default: "col-lg-4",
        },
        classTitle: {
            type: String,
            default: "col-lg-2",
        },
        classSearchOrder: {
            type: String,
            default: "col-lg-4",
        },
        classSideBar: {
            type: String,
            default: "col-xl-3",
        },
        classColOrder: {
            type: String,
            default: "col-12 col-lg-4",
        },
        classBtnSearch: {
            type: String,
            default: "col-12 col-md-2 mt-3",
        },
        hideTotal: {
            type: Boolean,
            default: false,
        },
        showBtnSearch: {
            type: Boolean,
            default: false,
        },
        showViewOpt: {
            type: Boolean,
            default: false,
        },
        hideOrder: {
            type: Boolean,
            default: false,
        },
        hideSearch: {
            type: Boolean,
            default: false,
        },
        hideTitle: {
            type: Boolean,
            default: false,
        },
        hideSide: {
            type: Boolean,
            default: false,
        },
        newFilter: {
            type: Boolean,
            default: false,
        },
        hideTop: {
            type: Boolean,
            default: false,
        },
        link: {
            type: String,
            require: true,
        },
        fakeItems: {
            type: [Array, Object],
            default: () => null,
        },
        orderOpts: {
            type: [Array, Object],
            default: () => {
                return [
                    { id: "desc", name: "الاحدث" },
                    { id: "asc", name: "الاقدم" },
                ];
            },
        },
        orderName: {
            type: String,
            default: "created_at",
        },
        callList: {
            type: Function,
            default: null,
        },
        group: {
            type: String,
            default: "d-filter-list",
        },
        HeadEndClasses: {
            type: String,
            default: "col-12 col-lg-auto",
        },
    },
    data: () => ({
        providersPage: false,
        readyServicesPage: false,
        proposalPage: false,
        states: [
            { id: null, name: "الكل" },
            { id: "online", name: "خدمات اونلاين" },
            { id: "offline", name: "خدمات اوفلاين" },
        ],
        locations: [
            { id: null, name: "الكل" },
            { id: "saudi", name: "السعوديه" },
            { id: "egypt", name: "مصر" },
        ],
        stateValue: null,
        filter: {
            order: "desc",
            search: "",
        },
        metaInfo: {
            current_page: 1,
            to: 10,
            total: 0,
            total_page: 0,
        },
        valueMax1: 40,
        valueMin1: 0,
        valueMax2: 50,
        valueMin2: 20,
        items: [],
        showList: false,
        showGrid: true,
    }),
    computed: {
        userIsRoleProvider() {
            return this.$store.state.clientOrProvider;
        },
    },
    methods: {
        async checkUser() {
            let data = await window.axios.post(`user/me`);
            let { ...user } = data.data.data;
            return user;
        },
        async openAddService(evt) {
            evt.preventDefault();
            if (this.proposalPage) {
                this.$router.push({
                    name: "service-provider-proposals-add",
                });
            } else if (!this.userIsRoleProvider) {
                this.$router.push({
                    name: "service-provider-proposals-add",
                });
            } else {
                let checkUser = await this.checkUser();
                if (checkUser.serviceProviderPortfolio === null) {
                    let dataEvt = {
                        title: "للأسف لايمكنك  اضافة  خدمه جديده",
                        description: `يجب عليك انشاء ملف اعمالك اولا لكى تستطيع اضافه خدماتك`,
                        image: `${this.publicPath}assets/img/Group 1171275670.png`,
                        btns: [
                            {
                                title: "انشاء ملف اعمالك",
                                action: () =>
                                    this.$router.push({
                                        name: "create-profile-service-provider",
                                    }),
                            },
                        ],
                    };
                    this.showConfirmMsg(dataEvt);
                    return;
                } else {
                    this.fireOpenDialog("add-ready-service-dialog");
                }
            }
        },
        //  openAddService(evt) {
        //     evt.preventDefault();
        //     if (!this.userIsRoleProvider) this.fireOpenDialog("add-proposal");
        //     else this.fireOpenDialog("add-ready-service-dialog");
        // },
        closeAddService(evt) {
            evt.preventDefault();
            this.fireCloseDialog("add-ready-service-dialog");
        },
        updateFilter() {
            let obj = {};
            obj[this.orderName] = this.filter.order;
            obj.search = this.filter.search;
            this.$emit("change", obj);
        },
        changePage(page) {
            if (this.metaInfo.current_page !== page) {
                this.metaInfo.current_page = page;
            }
            this.loadList();
        },
        refreshList(evt) {
            if (evt && evt.page) {
                this.changePage(evt.page);
            } else this.changePage(1);
            //this.loadList()
        },
        async loadList() {
            if (this.fakeItems) {
                this.items = this.fakeItems;
                return;
            }
            if (!this.callList && !this.link) return;
            try {
                //let { data } =await this.callList(this.metaInfo)
                let { data } = this.callList
                    ? await this.callList(this.metaInfo)
                    : await this.$axios.get(
                          `${this.link}?page=${this.metaInfo.current_page}`,
                      );

                if (data.success) {
                    this.items = data.data;
                    this.metaInfo.to = data.meta.to;
                    this.metaInfo.total = data.meta.total;
                    this.metaInfo.total_page = data.meta.last_page;
                }
            } catch (error) {
                console.mylog("error", error);
                console.mylog("response", error.response);
            }
        },
        changeView(type) {
            if (type === "grid") {
                this.showGrid = true;
                this.showList = false;
            } else {
                this.showGrid = false;
                this.showList = true;
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.loadList();
        });
    },
    created() {
        window.EventBus.listen(this.group + "-change-page", this.changePage);
        window.EventBus.listen(this.group + "-refresh", this.refreshList);
        this.providersPage = this.$route.path.endsWith(
            "/service-provider/providers",
        );
        this.readyServicesPage = this.$route.path.endsWith(
            "service-provider/ready-services",
        );
        this.proposalPage = this.$route.path.endsWith(
            "service-provider/proposals",
        );
    },
    beforeDestroy() {
        window.EventBus.off(this.group + "-change-page", this.changePage);
        window.EventBus.off(this.group + "-refresh", this.refreshList);
    },
};
</script>

<style lang="scss" scoped>
.pagination {
    .prev-btn,
    .next-btn,
    .page-link {
        width: 38px;
        height: 38px;
        border-radius: 6px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #bdbdbd !important;
    }

    .prev-btn,
    .next-btn {
        background: transparent !important;
    }

    .page-item {
        .page-number {
            font-size: 1rem !important;
            font-weight: 500 !important;
        }
        &.disabled {
            .page-number {
                background-color: #1fb9b3 !important;
                border: 1px solid #1fb9b3 !important;
                color: #fff !important;
            }
        }
    }
}
.req-btn {
    background-color: #f2631c !important;
    border: 1px solid #f2631c !important;
    color: #fff !important;
    border-radius: 8px;
}
.style-buttons button {
    color: #fff;
    background: #2cb7b3;
    padding: 5px 10px;
    border: 1px solid;
}

.provider select {
    height: auto !important;
}
.provider .form-select {
    background-position: calc(100% - 10px) center !important;
}

@media (max-width: 991px) {
    .margin {
        margin-top: 15px !important;
    }
}
</style>
